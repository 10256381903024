




































import Vue from 'vue'
import request from '@/utils/request'
import expertInfo from './expert_info.vue'
export default Vue.extend({
    props: {
        name: {type: String},
        industry: {type: String},
        areaCode: {type: String},
    },
    components: {
        expertInfo,
    },
    data() {
        return {
            loading: false,
            pageNum: 1,
            pageSize: 10,
            total: 0,
            data: [],
            more: false,
            index: null,
        }
    },
    computed: {
        current(): any {
            return this.$store.state.current
        },
        changed(): string {
            return this.name + this.industry + this.areaCode
        }
    },
    watch: {
        changed() {
            this.initData()
        },
    },
    mounted() {
        this.initData()
    },
    methods: {
        initData() {
            this.pageNum = 1
            this.getData()
        },
        onLoad() {
            this.pageNum += 1
            this.getData()
        },
        getData() {
            this.loading = true
            const { pageNum, pageSize, name, industry, areaCode } = this
            const params = { pageNum, pageSize, name, industry, areaCode }
            request.get('/api/tobacco/rest_expert/page', params).then((res: any) => {
                const {list, total} = res
                this.getExpertImg(list)
                // console.log(list)
                if(pageNum==1) this.data = list
                else this.data = this.data.concat(list)
                this.total = total
                this.loading = false
            })
        },
        async getExpertImg(list) {
            for (let e of list) {
                // console.log(e)
                let imgurlhead = ''
                let imgUrl = ''
                // if(typeof e.relationId !=='undefined'){
                  await request.get(`/api/account/rest_account/${e.relationId}`).then(res => {
                      if (res) {
                          imgurlhead = res.headimgurl
                          imgUrl = ''
                      }
                  })
                // }

                if(imgurlhead ===''){
                  if (e.sex === 1) {
                    imgUrl = '/static/images/sex_1.png'
                  } else if (e.sex === 2) {
                    imgUrl = '/static/images/sex_2.png'
                  } else {
                    imgUrl = '/static/images/sex_0.png'
                  }
                }
                this.$set(e, 'imgurlhead', imgurlhead)
                this.$set(e, 'imgUrl', imgUrl)
                // e.imgurlhead = imgurlhead
                // e.imgUrl = imgUrl
                // console.log(imgurlhead)
                // console.log(imgUrl)
            }
        },
        view(item: any) {
            // console.log(item)
            // this.$router.push({
            //     path: `/im/${item.id}`,
            //     query: {
            //         headimgurl: item.imgurlhead,
            //     }
            // })
            this.$router.push({
                path: `/im_new/null`,
                query: {
                    name: item.name,
                    relationId: item.relationId,
                }
            })
        },
        showMore(i) {
            const index = this.index
            if (this.index === i) {
                let more = null
                if (this.more) {
                    more = false
                } else {
                    more = true
                }
                this.more = more
            } else {
                this.index = i
                this.more = true
            }
        },
    }
})
