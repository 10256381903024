































































import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
const storagePrefix = 'cz.tobacco.h5.'

export default Vue.extend({
    data() {
        return {
            loading: false,
            pageNum: 1,
            pageSize: 10,
            total: 0,
            data: [],
        }
    },
    computed: {
        current(): any {
            return this.$store.state.current
        },
    },
    mounted() {
        this.onInit()
    },
    methods: {
        onInit() {
            this.pageNum = 1
            this.getData()
        },
        onLoad() {
            this.pageNum += 1
            this.getData()
        },
        getData() {
            this.loading = true
            const { pageNum, pageSize } = this
            const params = { pageNum, pageSize, type: 4 }
            // request.get('/api/chat/rest_chat/all_chat_page', params).then(({list, total}) => {
            //     // console.log(list)
            //     this.getImg(list)
            //     this.getExpertImg(list)
            //     // console.log(list)
            //     if(pageNum==1) this.data = list
            //     else this.data = this.data.concat(list)
            //     this.total = total
            //     this.loading = false
            // })
            request.get('/api/chat/rest_live_chat/all/page', params).then(({list, total})=> {
                // console.log(list)
                // list.map((e: any, index) => {
                //     console.log(JSON.parse(JSON.stringify(e)))
                // })
                if(pageNum==1) this.data = list
                else this.data = this.data.concat(list)
                this.total = total
                this.loading = false
            })
        },
        async getImg(list) {
            for (let e of list) {
                // console.log(e)
                let headimgurl = ''
                let name = ''
                await request.get(`/api/account/rest_account/${e.fromId}`).then(res => {
                    if (res) {
                        headimgurl = res.headimgurl?res.headimgurl:"/static/images/sex_0.png"
                        name = res.realName ? res.realName : res.nickname
                        // name = res.nickname
                    }
                })

                this.$set(e, 'headimgurl', headimgurl)
                this.$set(e, 'name', name)
            }
        },
        async getExpertImg(list) {
            for (let e of list) {
                if (e.expertChat) {
                    // console.log(e.expertChat.id)
                    if(e.expertChat.id) {
                        let relationId = ''
                        await request.get(`/api/tobacco/expert/${e.expertChat.fromId}`).then(data => {
                            relationId = data.relationId
                            this.$set(e.expertChat, 'name', data.name)
                        })
                        // console.log(relationId)
                        const obj = await this.getExpertImgTwo(e.expertChat, relationId)
                        this.$set(e.expertChat, 'headimgurl', obj.headimgurl)
                    } else {
                        this.$set(e.expertChat, 'headimgurl', '/static/images/sex_0.png')
                        this.$set(e.expertChat, 'name', '专家')
                    }
                }
            }
        },
        getExpertImgTwo(expertChat, relationId) {
            let headimgurl = ''
            return request.get(`/api/account/rest_account/${relationId}`).then(res => {
                if (res) {
                    headimgurl = res.headimgurl
                } else {
                    if (expertChat.sex === 1) {
                        headimgurl = '/static/images/sex_1.png'
                    } else if (expertChat.sex === 2) {
                        headimgurl = '/static/images/sex_2.png'
                    } else {
                        headimgurl = '/static/images/sex_0.png'
                    }
                }
                return {headimgurl}
            })
        },
        view(item: any) {
            // console.log(item)
            if (item.targetInfo && item.targetInfo.id === this.current.id) {
                this.$router.push({
                    path: `/im_new/${item.id}`,
                })
            } else if (item.creatorInfo && item.creatorInfo.id === this.current.id) {
                this.$router.push({
                    path: `/im_new/${item.id}`,
                })
            } else {
                this.$router.push({
                    path: '/consulting_view',
                    query: {
                        id: item.id,
                    },
                })
            }
        },
        view_old(item: any) {
            // console.log(item)
            // console.log(item.toId)
            // console.log(item.fromId)
            // console.log(this.$store.state.current)
            // console.log(this.$store.state.managerInfo)
            let headimgurl = ''
            let name = ''
            if (item.expertChat) {
                headimgurl = item.expertChat.headimgurl
                name = item.expertChat.name
            }
            const {role} = this.$store.state.current
            // console.log(role)
            if (role === 1) {
                // console.log(item.toId)
                if(item.toId !== this.$store.state.managerInfo.id) {
                    this.$router.push({
                        path: '/consulting_view',
                        query: {
                            toId: item.toId,
                            fromId: item.fromId,
                            expertHeadimgurl: headimgurl,
                            expertName: name,
                            headimgurl: item.headimgurl,
                            name: item.name,
                        },
                    })
                } else {
                    this.$router.push({
                        path: `/im/${item.fromId}`,
                        query: {
                            toId: item.toId,
                            fromId: item.fromId,
                            headimgurl
                        }
                    })
                }
            } else {
                // console.log(headimgurl)
                // console.log(this.$store.state.current.id)
                if(item.fromId !== this.$store.state.current.id) {
                    this.$router.push({
                        path: '/consulting_view',
                        query: {
                            toId: item.toId,
                            fromId: item.fromId,
                            expertHeadimgurl: headimgurl,
                            expertName: name,
                            headimgurl: item.headimgurl,
                            name: item.name,
                        },
                    })
                } else {
                    this.$router.push({
                        path: `/im/${item.toId}`,
                        query: {
                            toId: item.toId,
                            fromId: item.fromId,
                            headimgurl
                        }
                    })
                }
            }
        }
    },
    filters: {
        dtFormat(timestamp: number) {
            const time = moment(timestamp)
            return time.format('YYYY-MM-DD HH:mm')
        }
    }
})
